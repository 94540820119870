import React from 'react'

function Container(props) {
  return (
    <div className='max-w-[1290px] ml-auto mr-auto'>
        {props.children}
    </div>

  )
}

export default Container
import React from 'react'

function PartnerIndividualv2(props) {
  return (
    <div>
        <div className='flex flex-col items-center space-y-8 md:flex-row md:items-start md:space-x-8 md:space-y-0'>
            <img src={require(`../assets/${props.image}`)} className='max-w-[109px] md:max-w-[150px] h-auto'/>
            {/* <div className='flex flex-col space-y-8 items-center md:flex-row md:items-start md:space-y-0 md:space-x-4'>
                <div className='w-[27px] h-[3px] bg-Primary md:mt-4'/>      
                <p className='w-full max-w-[206px] md:max-w-[300px] lg:max-w-[507px] text-[14px] md:text-[20px] text-center md:text-left text-black'>{props.text}</p>
            </div> */}
        </div>
    </div>
  )
}

export default PartnerIndividualv2
import React from 'react'
import ContactHero from '../components/sections/ContactHero'
import QuickContact from '../components/sections/QuickContact'
import SectionMap from '../components/sections/SectionMap'

function Contact() {
  return (
    <>
        <ContactHero/>
        <QuickContact/>
        <SectionMap/>
    </>
  )
}

export default Contact
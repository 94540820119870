import React from 'react'
import Container from '../Container'
import Section from '../Section'

import HeroImg from '../../assets/hero_img_2.png'
import Display from '../Display'
import {Link} from 'react-router-dom'

function Hero() {
  return (
    <div className='bg-Primary'>
      <Section>
          <Container>
              <div className="flex bg-Primary text-white ">
                  <div className='w-full flex flex-col justify-center p-8'>
                      <div className='max-w-[270px] md:max-w-[300px] lg:max-w-[400px] md:ml-[32px]'>
                        <div className='mb-[25px] md:mb-[48px] lg:mb-[100px]'>
                          <Display>Tehničke i inženjerske usluge</Display>
                        </div>
                        <h5 className='mb-[25px] md:mb-[30px] lg:mb-[50px]'>Mi smo <span className='font-bold'>Inženjering-projekt d.o.o.</span>, zajedno možemo izgraditi blistavu budućnost</h5>
                        <Link to={'/kontakt'}><button className='bg-Accent px-[16px] py-[8px] text-[14px] md:text-[16px] rounded-[4px] hover:bg-AccentDark block md:mb-[8px] lg:mb-[16px]'>Kontaktiraj nas</button></Link>
                        <span className='text-[10px] md:text-[14px]'>Ili nazovi na +385 99 214 4852</span>
                      </div>
                  </div>
                  <div className='w-full hidden md:block'>
                      <img src={HeroImg} className='h-full w-full object-cover'/>
                  </div>
              </div>
          </Container>
      </Section>
    </div>
  )
}

export default Hero
import React from 'react'
import Container from '../Container'
import Section from '../Section'

function Stats() {
  return (
    <Section>
        <Container>
            <div className='p-8 flex justify-around'>
                <div className='max-w-[94px]'>
                    <p className='text-[20px] lg:text-[24px] text-center'><p className='font-bold'>15+</p>godina iskustva</p>
                </div>
                <div className='max-w-[94px]'>
                    <p className='text-[20px] lg:text-[24px] text-center'><p className='font-bold'>100+</p>završenih projekata</p>
                </div>
                <div className='max-w-[94px] hidden md:block'>
                    <p className='text-[20px] lg:text-[24px] text-center'><p className='font-bold'>10+</p>stalnih suradnika</p>
                </div>
            </div>
        </Container>
    </Section>
  )
}

export default Stats
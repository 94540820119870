import React from 'react'
import Container from '../Container'
import Section from '../Section'





function ContactHero() {
  return (
    <Section>
        <Container>
            <div className='bg-Primary py-24 px-8 md:px-24'>
                <h1 className='font-Heading text-white'>Kontakt</h1>
            </div>
        </Container>
    </Section>
  )
}

export default ContactHero
import React from 'react'
import Card from '../Card'
import Container from '../Container'
import Section from '../Section'

function ServiceList() {
  return (
    <div className='mt-2 lg:mt-8'>
        <Section>
            <Container>
                <div className='py-4 lg:py-8'>
                    {/* <p className='w-full max-w-[288px] md:max-w-[512px] md:text-[24px]'>Kroz godine smo razvili lepezu usluga u kojima imamo uspješne rezultate</p> */}
                    <div className='flex flex-col items-center space-y-4 mt-8 md:grid md:grid-cols-3 md:gap-4 lg:gap-y-8 md:space-y-0'>
                        <Card title="Izrada idejnog riješenja za dobivanje lokacijske dozvole" image={'usluge_image_01.png'}/>
                        <Card title="Izrada glavnog projekta za dobivanje građevinske dozvole" image={'usluge_image_02.png'}/>
                        <Card title="Izrada izvedbenih projekata kao podloga za naruđbu pojedinih komponenti postrojenja" image={'usluge_image_03.png'}/>
                        <Card title="Izrada dokumentacije izvedenog stanja i priprema postrojenja za tehnički pregled" image={'documentation.png'}/>
                        <Card title="Izrada projekta grijanja, ventilacije i klimatizacije" image={'climatization.png'}/>
                        <Card title="Izrada radioničke dokumentacije čelične konstrukcije, izometrije cjevovoda" image={'pipes.png'}/>
                        <Card title="Vođenje nadzora" image={'construction.png'}/>
                        <Card title="Vođenje izgradnje industrijskih objekata" image={'managmant.png'}/>
                    </div>
                </div>
            </Container>
        </Section>
    </div>
  )
}

export default ServiceList
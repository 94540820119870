import React from 'react'
import Hero from '../components/sections/Hero'
import Stats from '../components/sections/Stats'
import QuickContact from '../components/sections/QuickContact'
import HomeUsluge from '../components/sections/HomeUsluge'
import Testemonials from '../components/sections/Testemonials'
import SectionMap from '../components/sections/SectionMap'



function Home() {
  return (
    <>
      <Hero/>
      <Stats/>
      <QuickContact/>
      <HomeUsluge/>
      {/* <Testemonials/> */}
      <SectionMap/>
    </>
  )
}

export default Home
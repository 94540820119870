import React from 'react'
import PartnersHero from '../components/sections/PartnersHero'
import PartnersList from '../components/sections/PartnersList'

function Partners() {
  return (
    <>
        <PartnersHero/>
        <PartnersList/>
    </>
  )
}

export default Partners
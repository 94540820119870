import React from 'react'
import FounderQuote from '../components/sections/FounderQuote'
import PageDisplay from '../components/sections/PageDisplay'

function About() {
  return (
    <>
        <PageDisplay/>
        {/* <FounderQuote/> */}
    </>
  )
}

export default About
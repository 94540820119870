import React from 'react'
import ServiceList from '../components/sections/ServiceList'
import ServicesHero from '../components/sections/ServicesHero'
import WannaWorkWithUs from '../components/sections/WannaWorkWithUs'

function Services() {
  return (
    <>
        <ServicesHero/> 
        <ServiceList/>
        <WannaWorkWithUs/>
    </>
  )
}

export default Services
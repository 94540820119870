import React from 'react'

function Section(props) {
  return (
    <div className='w-full h-auto px-[16px] relative'>
        {props.children}
    </div>
  )
}

export default Section
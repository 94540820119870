import React from 'react'

function Display(props) {
  return (
     <h1 className='font-Heading'>
        {props.children}
    </h1>
  )
}

export default Display
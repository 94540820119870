import React from 'react'
import Container from '../Container'
import PartnerIndividualv2 from '../PartnerIndividualv2'
import Section from '../Section'

function PartnersList() {
  return (
    <Section>
        <Container>
            <div className='flex flex-col items-center py-16'>
                <div className='w-full flex flex-col items-center space-y-16 md:items-end md:flex-row md:justify-around '>
                    <PartnerIndividualv2 image='cemex.png' text='Osnovan 1906. u Meksiku, CEMEX je od malog lokalnog igrača, brojnim akvizicijama, postao uspješna globalna kompanija koja osigurava građevinske proizvode najviše kvalitete i pouzdanu uslugu kupcima i zajednicama u više od 50 zemalja svijeta. Tvrtka broji gotovo 44.000 zaposlenika.'/>
                    <PartnerIndividualv2 image='cepin.png' text='Tvornica ulja Čepin vodeći je hrvatski prerađivač zrna uljarica s godišnjim kapacitetom prerade od 150 000 tona i proizvodnjom od 32 milijuna litara rafiniranih ulja godišnje.'/>
                    <PartnerIndividualv2 image='baktrotisak.png' text='Bakrotisak d.d. je društvo za proizvodnju fleksibilne ambalaže. Tvrtka je počela sa radom 1975. godine u Garešnici, te se uz dugogodišnje iskustvo dokazala kao stručan i vjeran partner svojih kupaca.'/>
                    <PartnerIndividualv2 image='eping.png' text='Poduzeća EP-ING d.o.o. godinama s uspjehom nastupaju na tržištu Hrvatske. Poduzeće je nastalo u okruženju holdinga Đuro Đaković, formirano iz jezgre djelatnika koji su ostvarili zapažene stručne rezultate na programima gradnje investicijskih objekata (cementara, šećerana,i svih vrsta čeličnih konstrukcija itd.).'/>
                    <PartnerIndividualv2 image='statera.png' text='Djelatnost kojom se tvrtka bavi je projektiranje i nadzor u građevinarstvu. Tvrtka je registrirana za većinu djelatnosti iz područja graditeljstva kao što su: Projektiranje, Nadzor izgradnje, Energetsko certificiranje jednostavnih i složenih sustava, itd.'/>
                    <PartnerIndividualv2 image='šecer.png' text='Tvornica šećera u Virovitici najmlađa je i najmodernija šećerana u Hrvatskoj. Gradnja tvornice započela je 1976. godine, a u probnu proizvodnju puštena je 1980. godine, s dnevnim kapacitetom prerade od 4.000 tona šećerne repe. Tvornica je od početka rada osposobljena i za preradu alternativnih sirovina za proizvodnju šećera, od kojih je najznačajniji uvozni sirovi šećer od šećerne trske. Od 1984. godine tvornica posluje u sastavu PIK-a Virovitica, a prestankom njegova rada 1991. godine ponovo postaje samostalni poslovni subjekt.'/>
                    <PartnerIndividualv2 image='zito.png' text='Tvornica šećera u Virovitici najmlađa je i najmodernija šećerana u Hrvatskoj. Gradnja tvornice započela je 1976. godine, a u probnu proizvodnju puštena je 1980. godine, s dnevnim kapacitetom prerade od 4.000 tona šećerne repe. Tvornica je od početka rada osposobljena i za preradu alternativnih sirovina za proizvodnju šećera, od kojih je najznačajniji uvozni sirovi šećer od šećerne trske. Od 1984. godine tvornica posluje u sastavu PIK-a Virovitica, a prestankom njegova rada 1991. godine ponovo postaje samostalni poslovni subjekt.'/>
                
                </div>
            </div>
        </Container>
    </Section>
  )
}

export default PartnersList
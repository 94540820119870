import { Route, Routes } from 'react-router-dom';
import './App.css';
import Container from './components/Container';
import Footer from './components/Footer';
import FooterBottom from './components/FooterBottom';
import Navbar from './components/Navbar';
import ScrollToTop from './components/ScrollToTop';
import Section from './components/Section';
import About from './pages/About';
import Contact from './pages/Contact';
import Home from './pages/Home';
import Partners from './pages/Partners';
import Services from './pages/Services';

function App() {
  return (
    <>
      <Navbar/>
      <ScrollToTop>
        <Routes>
          <Route path='/' element={<Home/>}/>
          <Route path='/o-nama' element={<About/>}/>
          <Route path='/partneri' element={<Partners/>}/>
          <Route path='/usluge' element={<Services/>}/>
          <Route path='/kontakt' element={<Contact/>}/>
          <Route path="*" element={<Home />} />
          <Route path="/!TEST/kontakt.html" element={<Home />} />
        </Routes>
      </ScrollToTop>
      <Footer/>
      <FooterBottom/>
    </>
  );
}

export default App;

import React from 'react'
import Container from './Container'
import Section from './Section'

import logo from '../assets/inzenjering_white_wordmark_svg.svg'


function Footer() {
  return (
    <section className='bg-Primary mt-16'>
        <Section>       
            <Container>
                <div>
                    <div>
                        <div className='flex flex-col justify-between items-center md:flex-row text-white py-16'>
                            <div className='flex flex-col items-center md:items-start'>
                                <img src={logo} className="h-[18px] w-[142px] lg:w-[242px] lg:h-[30px] fill-white"/>
                                <p className='mt-2 text-center'>Zajedno gradimo budućnost</p>
                            </div>
                            <div className='flex flex-col items-center md:items-start mt-24 md:mt-0 text-[14px] md:text-[16px] md:space-y-1'>
                                <p className='mb-2'>Kontakt informacije</p>
                                <p>email: inzenjering-projekt@sb.t-com.hr</p>
                                <p>tel: +385 99 214 4852</p>
                                <p>adresa: 108. brigade ZNG 26, 35000 Slavonski Brod</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
         
        </Section>
    </section>
  )
}

export default Footer
import React from 'react'
import { Link } from 'react-router-dom'
import Card from '../Card'
import Container from '../Container'
import Section from '../Section'



function HomeUsluge() {
  return (
    <Section>
        <Container>
            <div className='py-16'>
                <h1>Naše usluge</h1>
                <p className='text-[20px] mb-16'>Neke od naših usluga koje nudimo preko 15 godina</p>
                <div className='flex flex-col md:flex-row md:justify-between items-center gap-10 mb-8'>
                    <Card title="Izrada idejnog riješenja za dobivanje lokacijske dozvole" image={'usluge_image_01.png'}/>
                    <Card title="Izrada glavnog projekta za dobivanje građevinske dozvole" image={'usluge_image_02.png'}/>
                    <Card title="Izrada izvedbenih projekata kao podloga za naruđbu pojedinih komponenti postrojenja" image={'usluge_image_03.png'}/>

                </div>
                 <div className='flex justify-center'>
                    <Link to='/usluge' className='bg-slate-300 px-[16px] py-[8px] text-[14px] md:text-[16px] rounded-[4px] hover:bg-slate-500 block md:mb-[8px] lg:mb-[16px]'>
                        Pogledaj više
                    </Link>
                 </div>
            </div>
        </Container>
    </Section>
  )
}

export default HomeUsluge
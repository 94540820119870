import React from 'react'
import Container from './Container'
import Section from './Section'
import {Link} from 'react-router-dom'


function FooterBottom() {
  return (
    <Section>
        <Container>
            <div>
                <div className='flex flex-col md:flex-row justify-between py-8 md:py-4 space-y-16 md:space-y-0'>
                    <div>
                        <ul className='flex flex-col md:flex-row items-center space-y-2 md:space-y-0 md:space-x-4'>
                        <Link to={'/'} ><li>Naslovna</li></Link>
                        <Link to={'/usluge'} ><li>Usluge</li></Link>
                        <Link to={'/partneri'} ><li>Partneri</li></Link>
                        <Link to={'/o-nama'} ><li>O nama</li></Link>
                        <Link to={'/kontakt'}><li className='cursor-pointer'>Kontaktiraj nas</li></Link>
                        </ul>
                    </div>
                    <div>
                        <p className='text-center md:text-start'>© 2022 Inženjering Projekt. All rights reserved</p>
                    </div>
                </div>
                <div className='flex flex-col items-center md:items-center py-1 text-sm border-t-[1px]'>
                    <a href='https://realstudio.digital/' target='_blank'>Designed by Real Studio</a>
                </div>
            </div>
        </Container>
    </Section>
  )
}

export default FooterBottom
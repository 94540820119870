import React, { useState } from 'react'
import Container from './Container'
import Section from './Section'

import logo from '../assets/inzenjering_black_wordmark_svg.svg'
import mobileMenu from '../assets/hamb_menu.svg'
import { Link } from 'react-router-dom'

function Navbar() {


const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <Section>
        <Container>
            <div className='flex justify-between items-center py-[16px]'>
                <div>
                    <Link to={'/'} onClick={()=>setMobileMenuOpen(false)}><img src={logo} className="h-[18px] w-[142px] lg:w-[242px] lg:h-[30px]"/></Link>
                </div>
                
                <div className='hidden lg:block'>
                    <ul className='flex gap-[16px] items-center'>
                        <Link to={'/usluge'} ><li><a href='#'>Usluge</a></li></Link>
                        <Link to={'/o-nama'} ><li><a href='#'>O nama</a></li></Link>
                        <Link to={'/partneri'} ><li><a href='#'>Partneri</a></li></Link>
                        <Link to={'/kontakt'}><li><button className='bg-Accent cursor-pointer px-[16px] py-[8px] rounded-[4px] hover:bg-AccentDark text-white'>Kontaktiraj nas</button></li></Link>
                    </ul>
                </div>
                
                <button className='lg:hidden' onClick={()=>setMobileMenuOpen(!mobileMenuOpen)}>
                    <img src={mobileMenu}/>
                </button>
                
                <div className={`absolute lg:hidden z-50 left-0  w-full bg-white border border-slate-300 shadow-xl transition-all duration-500 ease-in ${!mobileMenuOpen ? 'top-[-500%]' : 'top-[100%]'}`}>
                    <ul className='flex flex-col items-center gap-[16px] p-4'>
                        <Link to={'/usluge'} onClick={()=>setMobileMenuOpen(!mobileMenuOpen)}><li className='border-b-2 border-b-slate-100 w-full text-center pb-2'>Usluge</li></Link>
                        <Link to={'/o-nama'} onClick={()=>setMobileMenuOpen(!mobileMenuOpen)}><li className='border-b-2 border-b-slate-100 w-full text-center pb-2'>O nama</li></Link>
                        <Link to={'/partneri'} onClick={()=>setMobileMenuOpen(!mobileMenuOpen)} ><li className='border-b-2 border-b-slate-100 w-full text-center pb-2'>Partneri</li></Link>
                        <Link to={"/kontakt"} onClick={()=>setMobileMenuOpen(!mobileMenuOpen)}><li>Kontaktiraj nas</li></Link>
                    </ul>
                </div>

            </div>
        </Container>
    </Section>
  )
}

export default Navbar
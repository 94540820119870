import React from 'react'
import Container from '../Container'
import Section from '../Section'

import CallIcon from '../../assets/call.svg'
import LocationIcon from '../../assets/location.svg'
import SmsIcon from '../../assets/sms.svg'



function QuickContact() {
  return (
    <Section>
        <Container>
            <section id='home_contact'>
                <div className='mt-8'>
                    <div className='px-4 py-10 flex flex-col md:flex-row md:items-start justify-around items-center bg-Primary rounded-md gap-8'>
                        <div className='text-center flex flex-col items-center text-white  flex-1 flex-grow'>
                            <img src={LocationIcon} className='w-[24px] h-[24px] lg:w-[33px] lg:h-[33px] mb-3'/>
                             <p className='text-[16px] lg:text-[20px]'>108. brigade ZNG 26</p>
                            <p className='text-[12px] lg:text-[16px]'>35000 Slavonski Brod</p>
                            <p className='text-[12px] lg:text-[16px]'>Hrvatska</p>
                        </div>
                        <div className='text-center flex flex-col items-center text-white  flex-1 flex-grow'>
                            <img src={CallIcon} className='w-[24px] h-[24px] lg:w-[33px] lg:h-[33px] mb-3'/>
                             <p className='text-[16px] lg:text-[20px]'>+385 99 214 4852</p>
                        </div>
                        <div className='text-center flex flex-col items-center text-white  flex-1 flex-grow'>
                            <img src={SmsIcon} className='w-[24px] h-[24px] lg:w-[33px] lg:h-[33px] mb-3'/>
                             <p className='text-[16px] lg:text-[20px]'>inzenjering-projekt@sb.t-com.hr</p>
                    
                        </div>
                    </div>
                </div>
            </section>
        </Container>
    </Section>
  )
}

export default QuickContact
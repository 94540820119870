import React from 'react'
import Container from '../Container'
import Section from '../Section'
import {GoogleMap, useLoadScript, MarkerF} from '@react-google-maps/api'
import { Processor } from 'postcss';
import '../../index.css'

function SectionMap() {

    const {isLoaded} = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_PUBLIC_GOOGLE_MAPS_API_KEY
    });


    if(!isLoaded) return (<><div>loading map...</div></>)

  return (
    <Section>
        <Container>
            <div className='mt-8'>
                <div className='bg-slate-200 w-full h-[300px] md:h-[400px] lg:h-[500px]'>
                    <Map/>
                </div>
            </div>
        </Container>
    </Section>
  )
}


export default SectionMap

function Map() {
    return (
     <GoogleMap zoom={16} center={{lat:45.165347, lng:18.005759}} mapContainerClassName="w-full h-full"><MarkerF position={{lat:45.165347, lng:18.005759}}/></GoogleMap>
     )
}


import React from 'react'
import Container from '../Container'
import Section from '../Section'

import logo from '../../assets/inzenjering_white_wordmark_svg.svg'


function PageDisplay() {
  return (
    <Section>
        <Container>
            <div className='bg-Primary p-8 md:p-32 text-white font-Heading md:text-[24px]'>
                <img src={logo}/>
                <div className='lg:ml-32'>
                    <p className='mt-16'>Djelatnost Društva INŽENJERING-PROJEKT d.o.o. bi bila projektiranje, inženjering, stručni nadzor na gradnji kompletnih građevina i/ili postrojenja po sustavu “ključ u ruke” i to za slijedeća područja: </p>
                    <ul className='mt-8 ml-8 list-disc max-w-[248px] lg:max-w-[500px]'>
                        <li>postojenja u industriji građevnog materijala (remont i rekonstrukcije)</li>
                        <li>naftna industrija i petrokemija (posude pod tlakom, spojni cjevovodi)</li>
                        <li>postrojenja u prehrambenoj industriji (tvornica šećera, uljara)</li>
                        <li>građevinski objekti tvorničke hale</li>
                        <li>energetska postrojenja kotlovnice</li>
                        <li>zaštita čovjekovog okoliša</li>
                    </ul>
                    <p className='mt-8'>Osnovno cilj tvrtke je zadovoljstvo kupca što se postiže aktivnom suradnjom s provjerenim dobavljačima - kooperantima. Na ovaj način djelatnici su u stanju prihvatiti i najsloženije izazove koji se javljaju na projektiranju i gradnji, odnosno rješavanju projektnih, tehničkih, tehnoloških i organizacijskih zadaća.</p>
                    {/* <p className='mt-8'>U sklopu izvođačkog inženjeringa obuhvaćene su sve aktivnosti iz područja gradnje, počevši dobivanja lokacijskih i građevinskih dozvola, projektiranjam, pa do gradnje, izvođenja stručnog nadzora, dokazivanja tehničkih parametara - probnog rada, te obuke kadrova kupaca.</p> */}
                </div>
            </div>
        </Container>
    </Section>
  )
}

export default PageDisplay
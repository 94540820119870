import React from 'react'

import Usluge01 from '../assets/usluge_image_01.png'
import CardGraphic from '../assets/card_graphic.png'
import Arrow from '../assets/Arrow.svg'
import { Link } from 'react-router-dom'


function Card(props) {
  return (
    <a className='group'>
        <div className='group max-w-[390px] md:max-w-[390px] overflow-hidden flex-1'>
            <div>
                <img src={require(`../assets/${props.image}`)} className='transition group-hover:scale-105 duration-500'>
        
                </img>
            </div>
            <div className='mt-[-80px] w-full h-[188px] z-10 relative py-8 pt-12 px-4' style={{backgroundImage: `url(${CardGraphic})`}}>
                <div className='flex flex-col justify-end h-full'>
                    <h2 className='text-[20px] font-Heading text-white max-w-[350px]'>{props.title}</h2>
                    {/* <div className='flex gap-2 text-white'>
                        <p>više</p>
                        <img src={Arrow} className='transition-all group-hover:scale-125'/>
                    </div> */}
                </div>
            </div>
            
        </div>
    </a>
  )
}

export default Card


import React from 'react'
import { Link } from 'react-router-dom'
import Container from '../Container'
import Section from '../Section'

function WannaWorkWithUs() {
  return (
    <div className='my-16'>
        <Section>
            <Container>
                <div className='p-8 md:p-16 bg-Primary rounded-md'>
                    <div className='flex flex-col items-center space-y-4 md:space-y-0 md:flex-row md:justify-around'>
                        <p className='text-[20px] md:text-[32px] text-white'>Želiš surađivati s nama?</p>
                        <Link to={'/kontakt'}><button className='bg-Accent px-[16px] py-[8px] text-[14px] md:text-[16px] rounded-[4px] hover:bg-AccentDark block md:mb-[8px] lg:mb-[16px] text-white'>Kontaktiraj nas</button></Link>
                    </div>
                </div>
            </Container>
        </Section>
    </div>
  )
}

export default WannaWorkWithUs